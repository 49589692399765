<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/load-public-sections";

export default {
  name: "LocalDisabled",

  metaInfo: {
    title: "Local desactivado",
  },

  extends: View,

  mixins: [LoadSections(["local-disabled"])],
};
</script>
